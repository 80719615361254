import { createContext, useRef, useState } from "react";

export const AdContext = createContext();

export const AdProvider = ({ children }) => {
  const primaryRewardedSlotPath = '/22632424389/appmobiquiz_reward'
  const secondaryRewardedSlotPath = '/22632424389/appmobiquiz_reward1'
  const [primaryRewardedEvent, setPrimaryRewardedEvent] = useState(null)
  const [secondaryRewardedEvent, setSecondaryRewardedEvent] = useState(null)
  const [lastRewardedShownType, setLastRewardedShownType] = useState('')
  const [rewardedSlotUsageStatus, setRewardedSlotUsageStatus] = useState({
    primary: false,
    secondary: false
  })
  const [lastQuizCoinWins, setLastQuizCoinWins] = useState(0)
  const isFirstPageRewarded = useRef(false)
  const rewardCoinAmount = useRef(0)

  const loadRewardedAd = (slotPath, shouldRedirect = false) => {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(function () {
      const rewardedSlot = window.googletag.defineOutOfPageSlot(
        slotPath,
        window.googletag.enums.OutOfPageFormat.REWARDED
      );
      if (rewardedSlot) {
        rewardedSlot
          .addService(window.googletag.companionAds())
          .addService(window.googletag.pubads());

        window.googletag.display(rewardedSlot);
      }
    })
    if (!isFirstPageRewarded.current && shouldRedirect) {
      window.location.replace('/home');
    }
    isFirstPageRewarded.current = false
  }

  const showRewardedAd = () => {
    if (primaryRewardedEvent && !rewardedSlotUsageStatus.primary) {
      primaryRewardedEvent?.makeRewardedVisible()
      setPrimaryRewardedEvent(null)
      setRewardedSlotUsageStatus(prev => {
        return { ...prev, primary: true }
      })
      return;
    }
    if (secondaryRewardedEvent && !rewardedSlotUsageStatus.secondary) {
      secondaryRewardedEvent?.makeRewardedVisible()
      setSecondaryRewardedEvent(null)
      setRewardedSlotUsageStatus(prev => {
        return { ...prev, secondary: false }
      })
      return;

    }
  }

  return (
    <AdContext.Provider
      value={{
        primaryRewardedSlotPath,
        primaryRewardedEvent,
        setPrimaryRewardedEvent,
        secondaryRewardedSlotPath,
        secondaryRewardedEvent,
        setSecondaryRewardedEvent,
        loadRewardedAd,
        showRewardedAd,
        lastRewardedShownType,
        setLastRewardedShownType,
        rewardedSlotUsageStatus,
        setRewardedSlotUsageStatus,
        rewardCoinAmount,
        lastQuizCoinWins,
        setLastQuizCoinWins,
        isFirstPageRewarded,
      }}
    >
      {children}
    </AdContext.Provider>
  );
};
