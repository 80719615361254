/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Coin from "../../assets/images/coin.svg";
import Play from "../../assets/images/play.svg";
import { useNavigate } from "react-router";
import axios from "axios";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AdsComponent from "../../AdComponent";

const data = [
  { id: 1, name: "All" },
  { id: 2, name: "Sports" },
  { id: 3, name: "Game" },
  { id: 4, name: "English" },
  { id: 5, name: "Full Science" },
  { id: 6, name: "Maths" },
  { id: 7, name: "Food" },
];

const CategoryList = ({ setImg, setName, base_url }) => {
  const [category, setCategory] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [fill, setFill] = useState(false);
  const [fillCategory, setFillCategory] = useState(false);
  const navigate = useNavigate();

  const handlePlay = (item) => {
    localStorage.setItem("categoryId", item?._id);
    setName(item?.description);
    setImg(item?.image);
    navigate("/home/quiz-details");
  };

  const CategoryList = async () => {
    setFill(true);
    setFillCategory(false);
    const res = await axios
      .get(base_url + "api/v1/category")
      .then((res) => {
        setCategory(res?.data?.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const subCategoryList = async () => {
    const res = await axios
      .get(base_url + `api/v1/subcategory/all`)
      .then((res) => {
        setCategoryData(res?.data?.subCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const subCategoryByList = async (item) => {
    setCategoryName(item?.category_name);
    setFillCategory(true);
    setFill(false);

    const res = await axios
      .get(base_url + `api/v1/subcategory/${item?._id}`)
      .then((res) => {
        setCategoryData(res?.data?.subCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    CategoryList();
  }, []);

  useEffect(() => {
    subCategoryList();
  }, []);

  return (
    <div className="text-white h-screen max-h-screen flex bg-primary overflow-x-hidden pt-[45px]">
      <div className="w-full h-full">
        <div
          className={`w-full max-w-[520px] ${
            categoryData?.length > 5 ? "h-full" : "h-screen"
          } flex flex-col bg-[#111827] gap-6 pb-3 items-center box-border`}
        >
          <AdsComponent dataAdSlot="/23020040970/native" />
          <div className="relative !z-40 bg-primary rounded-md shadow-lg w-full px-2">
            <Swiper
              slidesPerView={4}
              spaceBetween={10}
              slidesPerGroup={3}
              loop={false}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="cursor-pointer transition-all flex justify-center">
                  <div
                    onClick={subCategoryList}
                    className={`flex hover:bg-secondary justify-center border-2 border-border rounded-full px-3 ${
                      fill === true && "bg-secondary"
                    }`}
                  >
                    <div className={`flex-none px-2 mx-2 py-2 text-xs `}>
                      All
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              {category?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="cursor-pointer transition-all flex justify-center ml-5">
                    <div
                      className={`flex hover:bg-secondary justify-center border-2 border-border rounded-full px-3 ${
                        fillCategory === true &&
                        item?.category_name === categoryName &&
                        "bg-secondary"
                      }`}
                      onClick={() => subCategoryByList(item)}
                    >
                      <div className="flex-none px-2 mx-2 py-2 text-xs">
                        {item?.category_name}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="flex flex-col gap-6 w-full px-5 pb-10">
            {categoryData.length > 0 &&
              categoryData?.map((item, index) => (
                <div className="w-full" key={index}>
                  <div className="flex flex-col gap-2 w-full bg-primary border border-border rounded-full py-2 cursor-pointer">
                    <div className="flex gap-3 items-center px-2 justify-between">
                      <div className="flex flex-col">
                        <img
                          src={base_url + item?.image}
                          className="object-cover w-24 h-[65px] rounded-full"
                          alt="img"
                        />
                      </div>
                      <div className="flex flex-col w-full justify-start">
                        <div className="flex ms:text-[11px] text-text_hd font-black text-[9px] flex-col items-end">
                          <div className="text-[#64d2ff] max-h-[20px] py-[2px]">
                            <div className="flex ms:justify-center">
                              {item?.category_name} &nbsp;| &nbsp;{" "}
                              {item?.description}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-end flex-col mt-[5px]">
                          <div className="ms:text-[14px] text-[11px] font-black flex">
                            Play & Win &nbsp;&nbsp;
                            <img
                              src={Coin}
                              alt="coin"
                              className="w-[20px] object-contain"
                            />
                            &nbsp; 10000
                          </div>
                        </div>
                        <div className="flex items-end flex-col mt-[5px]">
                          <span className="ms:text-[11px] flex gap-1 text-[9px]  bg-[#30d158] bg-opacity-20 text-[#30d158] px-2 rounded-full">
                            Entry Fee &nbsp;{" "}
                            <img
                              src={Coin}
                              alt="img"
                              className="w-[14px] object-contain"
                            />
                            &nbsp; 100
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <img
                          src={Play}
                          alt="play"
                          className="rounded-full object-cover w-24"
                          onClick={() => handlePlay(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {categoryData.length === 0 && (
            <div className="flex flex-col gap-6 w-full px-5">
              <p>No data Found!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryList;
