import React, { useContext, useEffect, useRef } from "react";
import { AdContext } from "../contexts/adContext";
import { toast } from "react-toastify";

const RewardedAdComponent = ({ setCoin }) => {
  const {
    primaryRewardedSlotPath,
    secondaryRewardedSlotPath,
    primaryRewardedEvent,
    setPrimaryRewardedEvent,
    secondaryRewardedEvent,
    setSecondaryRewardedEvent,
    loadRewardedAd,
    setRewardedSlotUsageStatus,
    rewardCoinAmount,
  } = useContext(AdContext);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(function () {
        window.googletag
          .pubads()
          .addEventListener("rewardedSlotClosed", function (event) {
            rewardCoinAmount.current = 0;
            const slotName = event.slot.getSlotId().getName();
            loadRewardedAd(slotName);
          });

        window.googletag
          .pubads()
          .addEventListener("rewardedSlotReady", (event) => {
            const slotName = event.slot.getSlotId().getName();
            if (slotName === primaryRewardedSlotPath) {
              setPrimaryRewardedEvent(Object.assign({}, event));
              setRewardedSlotUsageStatus((prev) => {
                return { ...prev, primary: false };
              });
            }
            if (slotName === secondaryRewardedSlotPath) {
              setSecondaryRewardedEvent(Object.assign({}, event));
              setRewardedSlotUsageStatus((prev) => {
                return { ...prev, secondary: false };
              });
            }
          });

        window.googletag
          .pubads()
          .addEventListener("rewardedSlotGranted", (event) => {
            if (rewardCoinAmount.current) {
              const rewardToAdd =
                rewardCoinAmount.current > 0 ? rewardCoinAmount.current : 0;
              setCoin((prev) => +prev +rewardToAdd);
              rewardCoinAmount.current = 0;

              if (rewardToAdd) {
                toast.success(`${rewardToAdd} Coins Rewarded!`);
              }
            }
            const slotName = event.slot.getSlotId().getName();
            loadRewardedAd(slotName, true);
          });
        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().enableVideoAds();
        window.googletag.companionAds().setRefreshUnfilledSlots(true);
        window.googletag.enableServices();
      });
      window.googletag.cmd.push(function() {
        const anchorSlot = window.googletag.defineOutOfPageSlot(
          "/22632424389/AppMobiQuiz_Anchor",
          document.body.clientWidth <= 500
            ? window.googletag.enums.OutOfPageFormat.TOP_ANCHOR
            : window.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR,
        );
        if (anchorSlot) {
          anchorSlot.setTargeting("test", "anchor").addService(window.googletag.pubads());
        }
      })
      window.googletag.cmd.push(function () {
        const interstitialSlot = window.googletag.defineOutOfPageSlot(
          "/22632424389/appmobiquiz_Interstitial",
          window.googletag.enums.OutOfPageFormat.INTERSTITIAL,
        );

        if (interstitialSlot) {
          interstitialSlot.addService(window.googletag.pubads()).setConfig({
            interstitial: {
              triggers: {
                navBar: true,
                unhideWindow: true,
              },
            },
          });
          window.googletag.display(interstitialSlot);
        }
      })
      window.googletag.cmd.push(
        loadRewardedAd(primaryRewardedSlotPath),
        loadRewardedAd(secondaryRewardedSlotPath)
      );
    }

    return () => {
      const slotsToDestroy = [];
      if (primaryRewardedEvent?.slot)
        slotsToDestroy.push(primaryRewardedEvent?.slot);
      if (secondaryRewardedEvent?.slot)
        slotsToDestroy.push(secondaryRewardedEvent?.slot);
      slotsToDestroy.length && window.googletag.destroySlots(slotsToDestroy);
    };
  }, []);

  return <></>;
};

export default RewardedAdComponent;
