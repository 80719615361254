/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Coin from "../../assets/images/coin.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RewardModal from "../../modal/RewardModal";
import MainImg from '../../assets/images/main.png'
import AdsComponent from "../../AdComponent";
import InterstitialAd from "../../InterAd";
import { AdContext } from "../../contexts/adContext";

const Home = ({ base_url, setCoin, coin }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selected, setSelected] = useState();
  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const { showRewardedAd, isFirstPageRewarded } = useContext(AdContext)

  useEffect(() => {
    localStorage.removeItem("categoryId");
  }, []);

  const handleSelect = (i) => {
    if (selected === i && selected === questions[currentQuestion]?.answer)
      return "select";
    else if (selected === i && selected !== questions[currentQuestion]?.answer)
      return "wrong";
    else if (i === questions[currentQuestion]?.answer) return "select";
  };

  const handleCheck = (i) => {
    if (currentQuestion === questions?.length - 1) {
      setTimeout(() => {
        isFirstPageRewarded.current = true
        showRewardedAd()
        setIsOpen(true);
        // navigate("/home");
      }, 1000);
    }
    setSelected(i);
    if (i === questions[currentQuestion]?.answer) {
      setScore(score + 1);
      setCoin(+coin + 50);
    } else {
      setCoin(+coin - 10);
    }

    setTimeout(() => {
      if (i !== "") {
        setCurrentQuestion(currentQuestion + 1);
        setSelected();
      }
    }, 1000);
  };

  const QuestionList = async () => {
    const res = await axios
      .get(base_url + "api/v1/question/all")
      .then((res) => {
        setQuestions(res?.data?.questions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    QuestionList();
  }, []);

  useEffect(() => {
    if (isOpen === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);
  const showAd = () => {
    window.googletag = window.googletag || { cmd: [] };

    let interstitialSlot = null;

    window.googletag.cmd.push(function () {
      // Define a web interstitial ad slot.
      interstitialSlot = window.googletag.defineOutOfPageSlot(
        "/6355419/Travel/Europe/France/Paris",
        window.googletag.enums.OutOfPageFormat.INTERSTITIAL
      );

      // Slot returns null if the page or device does not support interstitials.
      if (interstitialSlot) {
        // Enable optional interstitial triggers and register the slot.
        interstitialSlot.addService(window.googletag.pubads()).setConfig({
          interstitial: {
            triggers: {
              navBar: true,
              unhideWindow: true
            }
          }
        });

        document.getElementById("status").textContent = "Interstitial is loading...";

        // Add event listener to enable navigation once the interstitial loads.
        // If this event doesn't fire, try clearing local storage and refreshing
        // the page.
        window.googletag.pubads().addEventListener("slotOnload", function (event) {
          if (interstitialSlot === event.slot) {
            document.getElementById("link").style.display = "block";
            document.getElementById("status").textContent = "Interstitial is loaded.";
          }
        });

        // By default, the detected language of the current page is used to render
        // interstitial UI elements. This behavior can be overridden by manually
        // specifying the document language:
        // googletag.pubads().set("document_language", "en");
      }

      // Define static ad slots.
      window.googletag
        .defineSlot("/6355419/Travel/Europe", [100, 100], "static-ad-1")
        .addService(window.googletag.pubads());

      // Enable SRA and services.
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();

      // Ensure the first call to display comes after static ad slot
      // divs are defined. If you do not have any static ad slots, this
      // call can be made immediately after services are enabled.
      window.googletag.display(interstitialSlot);
    });
  }
  return (
    <>
      <RewardModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        total={total}
        setCoin={setCoin}
        coin={coin}
      />

      <div className={`text-white h-auto flex bg-primary`}>
        <div className="w-full max-w-[520px] h-full flex flex-col gap-3 pb-3 px-2 items-center box-border">
          <AdsComponent dataAdSlot='/22632424389/Quizwebsite' className="pb-1" />
          <div className="text-center font-bold text-lg">
            Let's begin....!
            <div className="flex gap-1 text-[12px] text-[#8789c3]">
              Answer 2 questions and win
              <img className="w-3 object-contain" src={Coin} alt="coins"></img>
              100 free!
            </div>
          </div>
          <div className="text-[#bac8ff] font-bold">
            Question {currentQuestion + 1}/{questions?.length}
          </div>
          <div className="text-lg font-bold px-10 text-center">
            {questions[currentQuestion]?.question}
            {/* Which Country hosted Fifa World Cup 2022? */}
          </div>
          <div className="grid grid-cols-2 gap-3 px-3 min-w-full mt-4">
            {questions[currentQuestion]?.options?.map((option, index) => (
              <div
                className={`flex w-full text-center flex-col justify-center items-center text-[14px] py-2 min-h-[32px] bg-[#20213f] border-2 border-[#404380] rounded-full cursor-pointer ${selected && handleSelect(option)
                  }`}
                onClick={() => handleCheck(option)}
                disabled={selected}
                key={index}
              >
                {" "}
                {option}
              </div>
            ))}
          </div>
          {/* <div className="text-[#ffcc5b] font-bold cursor-pointer mb-6">
          Sign-Up - Login
        </div> */}
          <div className="w-full pl-5 mt-10">
            <div className="w-full font-bold text-lg">
              Play Quiz and Win Coins!
            </div>
            <ul className="text-[#8789c3] text-[14px] list-disc my-3 px-4">
              <li className="mb-2">
                Play Quizzes in 25+ categories like GK, Sports, Bollywood,
                Business, Cricket & more!
              </li>
              <li className="mb-2">Compete with lakhs of other players!</li>
              <li className="mb-2">Win coins for every game</li>
              <li className="mb-2">
                Trusted by millions of other quiz enthusiasts like YOU!
              </li>
            </ul>
          </div>
          <AdsComponent dataAdSlot='/22632424389/Quizwebsite' className="pb-1" />
          <div className="border-2 w-[100%] p-6 rounded-xl bg-white bg-opacity-10">
            <h1 className="text-2xl text-center pb-1 text-blue-500">
              Fun Facts
            </h1>
            <p className="text-[15px]">
              The average job search in the USA takes 5 months.LinkedIn is the
              most popular job search website in the USA, with over 700 million
              users.Monster is the second most popular job search website in the
              USA, with over 400 million users. Indeed is the third most popular
              job search website in the USA, with over 300 million users. 75% of
              resumes are rejected before even reaching a hiring manager. 2% of
              applicants make it through to the interview stage. 30-50% of jobs
              are filled through referrals. 75% of employers expect a thank-you
              note following an interview. The average time it takes for a
              hiring manager to know if they will hire that candidate is less
              than 2 minutes.
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Home;
