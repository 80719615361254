import React, { useContext } from "react";
import Animation from "../../assets/images/animation.gif";
import Coin from "../../assets/images/coin.svg";
import { useNavigate } from "react-router";
import AdsComponent from "../../AdComponent";
import { AdContext } from "../../contexts/adContext";

const WinDetails = ({ score, setScore, coin, setCoin }) => {
  const navigate = useNavigate();
  const { showRewardedAd, rewardCoinAmount, lastQuizCoinWins } = useContext(AdContext)

  const handleChange = () => {
    setScore(0);
    navigate("/home");
  };

  return (
    <div className="text-white h-screen flex bg-primary overflow-x-hidden pt-[45px]">
      <div className=" max-w-[520px] w-full h-full flex flex-col gap-3 items-center scrollhide box-border bg-[#111827]">
        <AdsComponent dataAdSlot='/22632424389/Quizwebsite' />
        <div className="px-5 pt-[2rem] pb-20 flex flex-col w-full gap-6">
          <div className="flex gap-2 items-center px-5 flex-col h-auto">
            <div className="flex justify-center items-center relative w-[200px]">
              <img
                src={Animation}
                className="h-[200px] absolute top-[-10px]"
                alt="animation"
              />
              <h1 className="text-white text-[34px]">Well Played</h1>
            </div>
            <div className="flex justify-evenly mt-20 gap-3 w-full">
              <div className="flex flex-col gap-2 sm:w-full bg-[#0e1344] border border-border rounded-full py-2 cursor-pointer w-48">
                <div className="text-white text-center flex flex-col">
                  <span>{score}</span>
                  <span> Your Score</span>
                </div>
              </div>
              <div className="flex flex-col gap-2 sm:w-full bg-[#0e1344] border border-border rounded-full py-2 cursor-pointer w-48">
                <div className="text-white text-center flex flex-col">
                  <span>{coin}</span>
                  <span> Coins Earned</span>
                </div>
              </div>
            </div>
            <div className="mt-2 cursor-pointer">
              <button className=" flex gap-2 rounded-full px-7 py-2 border-2 border-[#1a2f77]" onClick={() => {
                rewardCoinAmount.current = lastQuizCoinWins
                showRewardedAd()
              }} >
                Double Your winnings <img src={Coin} alt="coin" />
              </button>
            </div>
            <div
              style={{
                display: "block",
                height: "1px",
                borderWidth: "1px 0px 0px",
                borderTopStyle: "solid",
                borderRightStyle: "initial",
                borderBottomStyle: "initial",
                borderLeftStyle: "initial",
                borderTopColor: "rgb(26, 47, 119)",
                borderRightColor: "initial",
                borderBottomColor: "initial",
                borderLeftColor: "initial",
                borderImage: "initial",
                margin: "1em 0px",
                padding: "0px",
                width: "100%",
              }}
            ></div>
            <div className="flex flex-col justify-between">
              <button
                className="rounded-full px-7 py-2 bg-secondary"
                onClick={handleChange}
              >
                Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinDetails;
